import * as React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Text, Button } from '@superlogica/design-ui'

import { Link } from 'components/helpers'

const View: React.FC = () => (
  <Block padding={{ xxxs: '40px 0', sm: '80px 0' }} bgColor="babar50">
    <Container>
      <Title
        size="display02"
        font="heading"
        tag="h1"
        color="babar900"
        weight="bold"
        marginBottom={24}
      >
        Página não encontrada
      </Title>
      <Text color="babar900" marginBottom={24}>
        Desculpe, mas a página que você está procurando não foi encontrada.
      </Text>
      <Button
        tag={Link}
        title="Voltar para o início"
        color="charmander500"
        weight="semiBold"
        to="/"
      >
        Voltar para o início
      </Button>
    </Container>
  </Block>
)

export default View
